(function ($) {

    $.fn.geoCheck = function (thispage, endpoint) {

        let geodata = {};

        $.ajaxSetup({
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            }
        });

        $.ajax({
            url: endpoint,
            type: 'GET',
            data: geodata,
            dataType: "json",
            success: function (response) {

                redirectBrowser(response);

            },
            error: function (err) {
                console.error('error ' + thispage + endpoint, err);

                // show page event if geo error
                let startEvent = jQuery.Event("showPage");
                $("body").trigger(startEvent);

            }
        });

        let redirectBrowser = function (response) {
            let uri = "https://" + response.redirect;

            let barDismissed = window.localStorage.getItem('barDismissed');
            let popupDismissed = window.localStorage.getItem('popupDismissed');

            let currentTime = new Date().getTime();

            if (parseInt(currentTime) >= parseInt(barDismissed)) {
                localStorage.removeItem('barDismissed');
                barDismissed = null;
            }

            if (parseInt(currentTime) >= parseInt(popupDismissed)) {
                localStorage.removeItem('popupDismissed');
                popupDismissed = null;
            }

            if (response.available) {
                $('body').addClass('geobob-available');

                if (response.country_code === 'BR' && thispage === 'info.deprexis.com') {
                    $('body').addClass('geobob-' + response.country_code);
                } else {
                    $('body').addClass('geobob-XX');
                }
            } else {
                $('body').addClass('geobob-default');
            }

            if (!barDismissed || !popupDismissed) {

                if (response.redirect !== thispage) {

                    // add link in navbar
                    $('.nav-message .btn-redirect').attr({
                        'href': uri
                    }).text(response.redirect);

                    // add link in footer
                    $('.footer-redirect .lnk-redirect').attr({
                        'href': uri
                    }).text(response.redirect);
                    $('.footer-redirect').show();

                    $(".container-second a", ".body-redirect").text(thispage);
                    $(".container-main .btn-redirect", ".body-redirect").attr({
                        'href': uri
                    }).text(response.redirect);

                    if (!popupDismissed || !barDismissed) {

                        if (!popupDismissed) {

                            $(".container-second a").click(function (e) {
                                e.preventDefault();
                                $("body").removeClass("show-redirect");
                                setLocalStorage('popupDismissed');

                                $('.body-redirect').stop().animate({
                                    'opacity': 1
                                }, 10, function () {
                                    $('.body-redirect').animate({
                                        'opacity': 0
                                    }, 300, function () {
                                        $('.body-redirect').css({
                                            'display': 'none'
                                        });
                                    });
                                });

                            });

                            $("body").addClass("show-redirect");
                            $(".body-redirect").css({
                                'display': 'block'
                            });
                        }

                        if (!barDismissed) {
                            $('.navbar .nav-message').clone().appendTo('.buffer-top');
                            $('.nav-message').show(400);
                            $('body').addClass('nav-message-shown');
                        }

                    }
                }
            } else {
                if (response.redirect !== thispage) {
                    // add link in footer
                    $('.footer-redirect .lnk-redirect').attr({
                        'href': uri
                    }).text(response.redirect);
                    $('.footer-redirect').show();
                }
            }

            // show page
            let startEvent = jQuery.Event("showPage");
            $("body").trigger(startEvent);

        };

        $('.navbar .nav-message .btn-close').click(function (e) {
            e.preventDefault();

            let event = $.Event('hide.bob.geobar');
            $('.nav-message').first().trigger(event);

            $('.nav-message').hide(400, function () {
                $('.buffer-top').empty();
            });
        });

        let setLocalStorage = function (sName) {

            let timestamp = new Date().getTime();
            let expTime = parseInt(timestamp) + 86400000; // this is 24hours. Use 5000 for testing:)

            window.localStorage.setItem(sName, expTime);
        }

        $(document).on('hide.bob.modal', '.modal-confirm', function (e) {
            setLocalStorage('popupDismissed');
        });

        $(document).on('hide.bob.geobar', '.nav-message', function (e) {
            setLocalStorage('barDismissed');
        });
    };

}(jQuery));